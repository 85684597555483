import React from "react";
import "./Payment.css";

function Payment() {
  return (
    <div className="payment-component">
      <h2 className="dpsru-text-in-payment">
        DELHI PHARMACEUTICAL SCIENCES AND RESEARCH UNIVERSITY
      </h2>

      <div className="payment-container">
        <h3 className="how-to-pay-dpsru">How to Pay</h3>
        <div className="card-body-payment-how-to-pay">
         
          <ul className="m-0">
            <li>
              Go to State Bank website / Collect via : <span className="payment-text">
              <a
                // href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm"
                href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=802733"
                target="_blank"
                rel="noreferrer"
              >
                Click here
              </a>
              </span> /  Go to IDBI website / Collect via : <span className="payment-text">
              <a
                // href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm"
                href="https://idbi.isgpay.com/dpsru/"
                target="_blank"
                rel="noreferrer"
              >
                Click here
              </a>
              </span>
            </li>
           
            <li>
              Search for "DELHI PHARMACEUTICAL SCIENCE RESEARCH UNIVERSITY" in
              the search box and select it from the suggestions.
              <span className="d-block">Or, </span>
              Selects Category icon in the home page. Then selects the
              institution and 'Search by Name' or 'Filter'.
            </li>

            <li>
              Please input the details in the page (Example - Roll no., Name,
              Section, Fee amount, DOB, mobile number, captcha etc).{" "}
            </li>
            <li>Reads Terms &amp; conditions and clicks on check box.</li>
            <li>Clicks Next button.</li>
            <li>
              The State Bank Collect payment verification page will appear where
              the payer has to click on the 'Next' button after verifying the
              payment details to proceed further.
            </li>
            <li>
              System will display all payment modes enabled for the institution.
            </li>
            <li>
              You Can chooses the desired option and makes the payment. You may
              also print the receipt online.
            </li>
          </ul>
        </div>

        <h3 className="how-to-pay-dpsru">Payment Options</h3>
        <div className="payment-container">
          <div className="card-body-payment-how-to-pay">
            <ul>
              <li>Internet banking of SBI and others bank</li>
              <li>Debit cards (Visa/Master/ RuPaY) of SBI and other banks</li>
              <li>All Banks Credit Card</li>
              <li>UPI</li>
              <li>NEFT / RTGS. (For other than SBI customers)</li>
              <li>Pre-Paid cards</li>
              <li>Foreign Cards</li>
              <li>Branch option, Cash / SBI Cheque at SBI branches</li>
            </ul>
          </div>
          

        </div>

     


        <h2 className="payment-text">
          For Payment{" "} <br /> <br />


          <span>Link 1 (SBI)</span>
          <a
            href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=802733"
            target="_blank"
            rel="noreferrer"
          >
            Click here{" "}
          </a> <br /> <br />
          <span>Link 2 (IDBI)</span>
          <a
            href="https://idbi.isgpay.com/dpsru/"
            target="_blank"
            rel="noreferrer"
          >
            Click here{" "}
          </a>
        </h2>
      </div>
    </div>
  );
}

export default Payment;
