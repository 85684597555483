import React, { useState } from "react";
import { BiX } from "react-icons/bi";
import { VscMenu } from "react-icons/vsc";
import "./Nav.css";
import "./ResponsiveNav.css";
import img1 from "../Logo/img 1.1.png";
import img2 from "../Logo/img1.2.png";
import img3 from "../Logo/img 1.3.png";
import img4 from "../Logo/1.4.png";
import img5 from "../Logo/middle img.png";
import img6 from "../Logo/img 2.1.png";
import img7 from "../Logo/img 2.2.png";
import img8 from "../Logo/img 2.3.png";
import img9 from "../Logo/img 2.4.png";
import { NavLink, Link } from "react-router-dom";

import academicSkillHub from "./Acadmics-sec-pdf-Skill-Hub-Inititative-under-PMKVY-4.0-in-DPSRU.pdf";
import transeferOrder from "./Transfer Order.pdf";
// import revisedAdmissionScheduleofUgCourses2425 from '../Admissions/newData Of admissions/REVISED ADMISSION SCHEDULE OF UG COURSES 2024-25.pdf'
import revisedAdmissionScheduleofUgCourses2425 from "../Admissions/newData Of admissions/admission scheudule for all UG courses 2024-25.pdf";
import newRevisedAdmissionSchedule from "../Admissions/newData Of admissions/New Revised Admission Scedule.pdf";
import ExtensionofRegistrationdateforugcourses from "../Admissions/newData Of admissions/Extension of registration date for UG Courses.pdf";

// Merit list
import commonMeritListforDelhi from "../Admissions/Merit list data/Common Merit List for Delhi.pdf";
import commonMeritListfoNonDelhi from "../Admissions/Merit list data/Common merit list Non-Delhi.pdf";
import EWSMeritlistforDelhi from "../Admissions/Merit list data/EWS Merit List for Delhi.pdf";
import EWSMeritlistforNonDelhi from "../Admissions/Merit list data/EWS Merit List for NON Delhi.pdf";
import OBCMeritlistforDelhi from "../Admissions/Merit list data/OBC Merit List for Delhi.pdf";
import scMeritlistforDelhi from "../Admissions/Merit list data/SC Merit List for Delhi.pdf";
import scMeritlistforNonDelhi from "../Admissions/Merit list data/SC  Merit List for NON Delhi.pdf";
import STMeritlistforDelhi from "../Admissions/Merit list data/ST Merit List for Delhi.pdf";
import STMeritlistforNonDelhi from "../Admissions/Merit list data/ST Merit List for NON Delhi.pdf";
import MeriListofBscsportsscienceDelhi from "../Admissions/Merit list data/Merit List of B.Sc Sports Science DELHI.pdf";
import MeriListofBscsportsscienceNonDelhi from "../Admissions/Merit list data/Merit List of B.Sc Sports Science NON DELHI.pdf";
import pwdmeritlist2024 from "../Admissions/Merit list data/PWD merit list 2024.pdf";
import defenceMaritlistData from "../Admissions/Merit list data/Defence merit list 2024.pdf";
import kasmirimirgrantmeritlistdata from "../Admissions/Merit list data/Kashmiri Migrant Merit list new.pdf";
import naacAccreditationgradeseetjuly24 from "../Accreditations & Approvals/NAAC Accreditation Grade Sheet July 2024.pdf";
import academicCalender2425 from "../Academics/Academic calender-2024-25.pdf";
import organisatoinandfunction24file from "../RTI/Rti Act Section (4)(1)(b)/ORGANISATION AND FUNCTION (1).pdf";
import admissionscheduleofbpharmlaterentry from "../Admissions/newData Of admissions/Admission Schedule for B.Pharm. (Lateral Entry).pdf";
import commonMeritlistBpharmlenondelhi from "../Admissions/Merit list B pharm/Common Merit list B.Pharm LE Non delhi.pdf";
import ewsDelhiMeritlistbpharmle from "../Admissions/Merit list B pharm/EWS Delhi merit list B.Pharm L.E..pdf";
import commonmeritlistBpharmLedelhi from "../Admissions/Merit list B pharm/Common Merit list B.Pharm LE Delhi.pdf";
import meritlistBpharmleDefence from "../Admissions/Merit list B pharm/Common Merit list B.Pharm LE Defence.pdf";
import OBCMeritlistBphamrmledehi from "../Admissions/Merit list B pharm/OBC Delhi merit list B.Pharm L.E..pdf";
import SCDelhiMeritlistBpharmle from "../Admissions/Merit list B pharm/SC Delhi merit list B.Pharm L.E..pdf";
import StDelhiMeritlistBpharmle from "../Admissions/Merit list B pharm/ST Delhi merit list B.Pharm L.E..pdf";
import redwhedulingofbpharmleadmission9aug from "../Admissions/newData Of admissions/Rescheduling of B.Pharm LE. Admissions.pdf";
import ugadmissionschedulefo2ndroundofcouns2425 from "../Admissions/newData Of admissions/UG admuission schedule for 2nd round of counselling 2024-25.pdf";
import vaccanciesinugcoursesfrthe2ndroundcouns2425 from "../Admissions/newData Of admissions/vaccancies in UG courses for the 2nd round of counselling 2024-25.pdf";
import commonmeritlistformbahhmcoqp22delhiDpsru from "../Admissions/PG courses Merit list/Common merit List for MBA (COQP 22) Delhi DPSRU.pdf";
import commonmeritlistformbaHHMcoqp22nondelhi from "../Admissions/PG courses Merit list/Common merit List for MBA (COQP 22) NON Delhi.pdf";
import commonmeritlistmbaHHMcoqpcoqp22delhinonDPsru from "../Admissions/PG courses Merit list/Common merit List for MBA (COQP 22) Delhi NON DPSRU.pdf";
import commonmeritlistfrmbapmanditmcoqp12delhiDPSRU from "../Admissions/PG courses Merit list/Common merit List for MBA (COQP 12) Delhi DPSRU.pdf";
import commonmeritlistformbapmanditmcoqp12DelhinonDPSRU from "../Admissions/PG courses Merit list/Common merit List for MBA (COQP 12) Delhi NON DPSRU.pdf";
import commonmeritlistformbapmanditmcoqp12NONDelhi from "../Admissions/PG courses Merit list/Common merit List for MBA (COQP 12) NON Delhi.pdf";
import commonmeritlistmptnonDelhi from "../Admissions/PG courses Merit list/Common Merit list MPT Non Delhi.pdf";
import commonmeritlistMPTDelhi from "../Admissions/PG courses Merit list/Common Merit list MPT Delhi.pdf";
import AdmissionScheduleforMPT from "../Admissions/newData Of admissions/Admission schedule for MPT.pdf";
import AdmissionScheduleforMba from "../Admissions/newData Of admissions/Admission Schedule for MBA.pdf";
import slidingchagingofcoursebranchinUGProgramme from "../Admissions/newData Of admissions/Sliding Changing of Course Branch in UG Programme pdf.pdf";
import nd2roundcousellingormptprogrammesallbrances from "../Admissions/newData Of admissions/2nd Round counselling for MPT Programmes (All Branches).pdf";
import MpharmAdmissionschedule202425 from "../Admissions/newData Of admissions/M. Pharm Admission Schedule 2024-25.pdf";
import pgcoursemeritlistno01mpharmac from "../Admissions/PG courses Merit list/Provisional Merit List Of M.Pharm courses - [PG Course] - Non-DPSRU - Delhi.pdf";
import pgcoursemeritlistno01mpharmDelhi from "../Admissions/PG courses Merit list/Provisional Merit List Of All M.Pharm courses - [PG Course] - DPSRU - Delhi.pdf";
import pgcoursesmoharnondelhi from "../Admissions/PG courses Merit list/Merit list for M.Pharm Non Delhi.pdf";

import commonmeritlistforMphDelhinonDOSRU2425 from "../Admissions/PG courses Merit list/Common Merit List for MPH Delhi NON DPSRU 2024-25.pdf";

import commonmeritlistmphdelhimphdehodpsru2425 from "../Admissions/PG courses Merit list/Common Merit List for MPH Delhi DPSRU 2024-25.pdf";

import commonMeritlistforMPHnondelhi from "../Admissions/PG courses Merit list/Common merit List for MPH NON Delhi pdf.pdf";

import scheduleforpgAdmissionforMBAmph from "../Admissions/newData Of admissions/schedule for PG Admission for MBA (MPH).pdf";
import schedulefor2roundformbapmitggg from "../Admissions/newData Of admissions/Schedule for 2nd round for MBA (PM, ITM & HHM).pdf";
import AdmissionScheduleof2ndroundcounsellingforBSCss from "../Admissions/newData Of admissions/ADMISSION SCHEDULE Of  2nd Round Counselling For B.SC (SPORTS SCIENCE).pdf";

import commonMeritlistforexecutivempharmDra2425 from "../Admissions/newData Of admissions/Common Merit List for Executive M Pharm (DRA) 2024 25.pdf";

import SchedulecommonmeritListforDra2425 from "../Admissions/newData Of admissions/Schedule Common Merit List for Executive M. Pharm (DRA) 2024 25.pdf";
import noticeforMpharmexedra24 from "../Admissions/newData Of admissions/Notice for M. Pharm Exe. (DRA) pdf.pdf";
import schedule2ndroundCOunsellingforMpharm from "../Admissions/newData Of admissions/Schedule  2nd Round of Counselling for M.Pharm.pdf";
import vacanceyavailablefor2ndroundofcounsellformpharm from "../Admissions/newData Of admissions/vacancy available for 2nd round of counselling for M.Pharm.pdf";
import specialAdmissionforvariousugouses from "../Admissions/newData Of admissions/Special Admission Drive for various UG courses  (1).pdf";
import nonDelhiadmissionspeicaldrive from "../Admissions/newData Of admissions/Non Delhi Admissions speical drive pdf.pdf";
import secondcounofBpharmlateralentry from "../Admissions/newData Of admissions/SECOND COUNSELING of B.PHARM (LATERAL ENTRY).pdf";
import BpharmLateralEntryAdmissioninDpsugencat from "../Admissions/newData Of admissions/B.Pharm. (Lateral Entry) Admission in DPSRU, General Category pdf.pdf";

import newblink from "../NEW/new.gif";
import { FaSearch, FaHome } from "react-icons/fa";

// Slider
// Slider

import Carousel from "nuka-carousel";

function Nav() {
  const [respNavStatus, setRespNavStatus] = useState(false);
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];

  return (
    <div>
      <header className="nav-header">
        {/* upper header */}
        <div className="middle-header">
          <div className="upper-header-with-logo">
            <div className="middle-img-section">
              <Link to="/">
                <img className="middle-img" src={img5} alt="" />
              </Link>
            </div>

            <div className="middle-txt-section"></div>
            <div className="last-image-header-crousel">
              {/* renderBottomCenterControls={false} */}
              {/* withoutControls={true} */}
              <Carousel
                autoplay="true"
                // animation="zoom"
                animation="fade"
                pauseOnHover="true"
                breakPoints={breakPoints}
                wrapAround={true}
                withoutControls={true}
                autoplayInterval={5000}
              >
                <Link to="/dipsar">
                  <img src={img8} alt="logo" />
                </Link>
                <Link to="/school-of-physiotherapy">
                  <img src={img2} alt="logo" />
                </Link>
                <Link to="/wcsc">
                  <img src={img3} alt="logo" />
                </Link>
                {/* <Link to='/wcsc'>
                <img src={img4} alt="logo" />
                </Link> */}
                <Link to="/sahsm">
                  <img src={img1} alt="logo" />
                </Link>
                <Link to="/">
                  <img src={img6} alt="logo" />
                </Link>
                <a href="https://diif.in/">
                  <img src={img7} alt="logo" />
                </a>

                <Link to="/">
                  <img src={img9} alt="logo" />
                </Link>
              </Carousel>
            </div>
          </div>

          {/* Navigation  */}

          <div className="bottom-nav main-bottom-nav-for-hide-in-responsive">
            <nav className="navbar">
              <ul className="bottom-menu-ul">
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first bottom-menu-navLink-first-home-icon"
                    to="/"
                  >
                    <FaHome />
                  </NavLink>
                </li>
                <li className="bottom-menu-navLink-first">
                  <NavLink
                    to="/aboutUs"
                    className="sub-navlink-first bottom-menu-navLink-first"
                  >
                    {" "}
                    About us
                  </NavLink>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first" to="/rd">
                    R&D
                  </NavLink>
                </li>

                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/academics"
                  >
                    Academics
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Schools
                      </NavLink>
                      <ul>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/dipsar"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Delhi Institute of Pharmaceutical Sciences and
                            Reserch
                          </NavLink>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/soPharmaceticalSciences"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            School of Pharmaceutical Sciences
                          </NavLink>
                          <ul style={{ left: "100%", width: "80px" }}>
                            <li className="inner-li-of-school-inside-of-academics">
                              {" "}
                              <NavLink
                                to="/ayurveda"
                                className="sub-navlink-first bottom-menu-navLink-first"
                              >
                                Ayurveda
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/sahsm"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            School of Allied Health Sciences and Management
                          </NavLink>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/school-of-physiotherapy"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            School of Physiotherapy
                          </NavLink>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/doctoralPrograms"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Doctoral Programmes
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to="/"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Centres
                      </NavLink>
                      <ul>
                        <li className="inner-li-of-school-inside-of-academics-centers">
                          {" "}
                          <NavLink
                            to="/wcsc"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            World Class Skill Center
                          </NavLink>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics-centers">
                          {" "}
                        </li>
                        <li className="inner-li-of-school-inside-of-academics-centers">
                          {" "}
                          <NavLink
                            to="/assrm"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            ASSRM
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to="/centresOfExcellence"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Centres of Excellence
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/certificateCourses"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Certificate Courses
                      </NavLink>
                    </li>

                    <li>
                      <a
                        href={academicSkillHub}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Skill Hub PMKVY 4.0
                      </a>
                    </li>
                    <li>
                      <a
                        href={academicCalender2425}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Academic calender-2024-25
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/administration"
                  >
                    Administration
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink
                        to="/genralCounsil"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Governing Board
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/staff"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Administrative Staff
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/organogram"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Organogram
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/committees"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Committees
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/admissions"
                  >
                    Admissions <img src={newblink} alt="new" />
                  </NavLink>
                  <ul style={{ width: "400px" }}>
                    <li>
                      <a
                        href={BpharmLateralEntryAdmissioninDpsugencat}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                       B.Pharm. (Lateral Entry) Admission in DPSRU, General Category
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={secondcounofBpharmlateralentry}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        SECOND COUNSELING of B.PHARM (LATERAL ENTRY)
                        <img src={newblink} alt="new" />
                      </a>
                    </li>

                    <li>
                      <a
                        href={nonDelhiadmissionspeicaldrive}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Non Delhi Admissions special drive
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={specialAdmissionforvariousugouses}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Special Admission Drive for various UG courses
                        <img src={newblink} alt="new" />
                      </a>
                    </li>

                    <li>
                      <a
                        href={vacanceyavailablefor2ndroundofcounsellformpharm}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Vacancy available for 2nd round of counselling for
                        M.Pharm
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={schedule2ndroundCOunsellingforMpharm}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Schedule 2nd Round of Counselling for M.Pharm
                        <img src={newblink} alt="new" />
                      </a>
                    </li>

                    <li>
                      <a
                        href={noticeforMpharmexedra24}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Cancellation notification of the admissions in respect
                        of executive M. Pharm admissions scheduled on 17/9/2024
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={SchedulecommonmeritListforDra2425}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Schedule Common Merit List for Executive M. Pharm (DRA)
                        2024 25
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={commonMeritlistforexecutivempharmDra2425}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Common Merit List for Executive M Pharm (DRA) 2024 25
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={AdmissionScheduleof2ndroundcounsellingforBSCss}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        ADMISSION SCHEDULE Of 2nd Round Counselling For B.SC
                        (SPORTS SCIENCE)
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={schedulefor2roundformbapmitggg}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Schedule for 2nd round for MBA (PM, ITM & HHM)
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={scheduleforpgAdmissionforMBAmph}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        schedule for PG Admission for MBA (MPH)
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={MpharmAdmissionschedule202425}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        M. Pharm Admission Schedule 2024-25
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={nd2roundcousellingormptprogrammesallbrances}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        2nd Round counselling for MPT Programmes (All Branches)
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={AdmissionScheduleforMPT}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Admission schedule for MPT
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={AdmissionScheduleforMba}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Admission Schdule for MBA
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={slidingchagingofcoursebranchinUGProgramme}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Sliding Changing of Course Branch in UG Programme
                        <img src={newblink} alt="new" />
                      </a>
                    </li>

                    <li>
                      <a
                        href={ugadmissionschedulefo2ndroundofcouns2425}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        UG admission schedule for 2nd round of counselling
                        2024-25
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={vaccanciesinugcoursesfrthe2ndroundcouns2425}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        vaccancies in UG courses for the 2nd round of
                        counselling 2024-25
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={redwhedulingofbpharmleadmission9aug}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Rescheduling of B.Pharm LE. Admissions{" "}
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={admissionscheduleofbpharmlaterentry}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Admission Schedule for B.Pharm. (Lateral Entry){" "}
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={newRevisedAdmissionSchedule}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        New revised admission Schedule 2024-25{" "}
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={ExtensionofRegistrationdateforugcourses}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Extension of registration date for UG Courses{" "}
                        <img src={newblink} alt="new" />
                      </a>
                    </li>

                    <li>
                      <NavLink
                        to="/"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        PG Courses Merit List
                        <img src={newblink} alt="new" />
                      </NavLink>

                      <ul style={{ left: "400px" }}>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonMeritlistforMPHnondelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common merit List for MPH NON Delhi
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistmphdelhimphdehodpsru2425}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common Merit List for MPH Delhi DPSRU 2024-25
                          </a>
                        </li>

                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistforMphDelhinonDOSRU2425}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common Merit List for MPH Delhi NON DPSRU 2024-25
                          </a>
                        </li>

                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={pgcoursemeritlistno01mpharmDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Provisional Merit List Of All M.Pharm courses - [PG
                            Course] - DPSRU - Delhi
                          </a>
                        </li>

                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={pgcoursesmoharnondelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Provisional Merit List Of M.Pharm courses - [PG
                            Course] - Non Delhi
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={pgcoursemeritlistno01mpharmac}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Provisional Merit List Of M.Pharm courses - [PG
                            Course] - Non-DPSRU - Delhi
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistformbahhmcoqp22delhiDpsru}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common merit List for MBA (HHM) (COQP 22) Delhi
                            DPSRU
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistformbaHHMcoqp22nondelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common merit List for MBA (HHM) (COQP 22) NON Delhi
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistmbaHHMcoqpcoqp22delhinonDPsru}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common merit List for MBA ((HHM) COQP 22) Delhi NON
                            DPSRU
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistfrmbapmanditmcoqp12delhiDPSRU}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common merit List for MBA (PM & ITM) (COQP 12) Delhi
                            DPSRU
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={
                              commonmeritlistformbapmanditmcoqp12DelhinonDPSRU
                            }
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common merit List for MBA (PM & ITM) (COQP 12) Delhi
                            NON DPSRU
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistformbapmanditmcoqp12NONDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common merit List for MBA (PM & ITM) (COQP 12) NON
                            Delhi
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistmptnonDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common Merit list MPT Non Delhi
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistMPTDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common Merit list MPT Delhi
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <NavLink
                        to="/"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Merit List for B.Pharm. (Lateral Entry){" "}
                        <img src={newblink} alt="new" />
                      </NavLink>

                      <ul style={{ left: "400px" }}>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonMeritlistBpharmlenondelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common Merit list B.Pharm LE Non delhi
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={ewsDelhiMeritlistbpharmle}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            EWS Delhi merit list B.Pharm L.E.
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistBpharmLedelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common Merit list B.Pharm LE Delhi
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={meritlistBpharmleDefence}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common Merit list B.Pharm LE Defence
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={OBCMeritlistBphamrmledehi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            OBC Delhi merit list B.Pharm L.E.
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={SCDelhiMeritlistBpharmle}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            SC Delhi merit list B.Pharm L.E.
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={StDelhiMeritlistBpharmle}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            ST Delhi merit list B.Pharm L.E.
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <NavLink
                        to="/"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Merit List <img src={newblink} alt="new" />
                      </NavLink>

                      <ul style={{ left: "400px" }}>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonMeritListforDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common Merit List for Delhi
                          </a>
                        </li>

                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonMeritListfoNonDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common merit list Non-Delhi
                          </a>
                        </li>

                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={EWSMeritlistforDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            EWS Merit List for Delhi
                          </a>
                        </li>

                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={EWSMeritlistforNonDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            EWS Merit List for NON Delhi
                          </a>
                        </li>

                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={OBCMeritlistforDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            OBC Merit List for Delhi
                          </a>
                        </li>

                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={scMeritlistforDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            SC Merit List for Delhi
                          </a>
                        </li>

                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={scMeritlistforNonDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            SC  Merit List for NON Delhi
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={STMeritlistforDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            ST Merit List for Delhi
                          </a>
                        </li>

                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={STMeritlistforNonDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            ST Merit List for NON Delhi
                          </a>
                        </li>

                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={MeriListofBscsportsscienceDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Merit List of B.Sc Sports Science DELHI
                          </a>
                        </li>

                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={MeriListofBscsportsscienceNonDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Merit List of B.Sc Sports Science NON DELHI
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={pwdmeritlist2024}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            PWD merit list 2024
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={defenceMaritlistData}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Defence merit list 2024
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={kasmirimirgrantmeritlistdata}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            kashmiri migrants Merit list 2024
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first" to="/students">
                    Students
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/scholarShips"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Scholarships
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/hostel"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Hostal
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/examination"
                  >
                    Examinations
                  </NavLink>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first">IQAC</NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/iaqcIntroduction"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Introduction
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/iqacMoM"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        IQAC Committee (Composition and MoM)
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/QualityInitiativeIAQC"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Quality Initiatives
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/NationalRankingIAQC"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        National Rankings
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/International-Accolades"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        International Accolades
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/iaqcFeedback"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Feedback
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/iqacEvent"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        IQAC Events
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/iqacPolicy"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/contactUs"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Contact Us
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first">
                    Accreditations & Approvals
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/naac"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        NAAC
                      </NavLink>
                    </li>
                    <li>
                      <a
                        href={naacAccreditationgradeseetjuly24}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        NAAC Accreditation Grade Sheet July 2024
                      </a>
                    </li>
                    <li>
                      <NavLink
                        to="/nirf"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        NIRF
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/atalRanking"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Atal Rankings
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/approvals"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Approvals
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/ugc_12b"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        UGC 12B
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first" to="/">
                    RTI
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/rtiAct"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        RTI Act
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="sub-navlink-first bottom-menu-navLink-first">
                        Section (4)(1)(b) of RTI Act
                      </NavLink>
                      <ul>
                        <li>
                          <NavLink
                            to="/organizationAndFunction"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Organization and Function
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/budgetAndProgramme"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Budget and Programme
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/ppiRti"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Publicity and Public Interface
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/eGovernance"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            E-Governance
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/informationAsMayBePrescribed"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Information as may be prescribed
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/informationDisclosedOnOwnInitiative"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Information Disclosed on own Initiative
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/annualReportOfRti"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Annual Report of RTI
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to="/cpioAndFaa"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        CPIO and FAA
                      </NavLink>
                    </li>
                  </ul>
                </li>

                {/* Events  */}
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/events
                    "
                  >
                    Events
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink
                        to="/conference"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Conference
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/ucps"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Seminar
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/workshop"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Workshop
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/g20"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        G20
                      </NavLink>
                      <ul>
                        <li>
                          {" "}
                          <NavLink
                            to="/g20PanelDiscussionReport"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Panel Discussion
                          </NavLink>
                        </li>
                        <li>
                          {" "}
                          <NavLink
                            to="/indoBrazilFusionFest"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Indo Brazil Fusion Fest
                          </NavLink>
                        </li>
                        <li>
                          {" "}
                          <NavLink
                            to="/pushpPradarshnam"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Pushp Pradarshnam 2023
                          </NavLink>
                        </li>
                        <li>
                          {" "}
                          <NavLink
                            to="/G20MahaFoodFestival"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            G20 maha food festival
                          </NavLink>
                        </li>
                        <li>
                          {" "}
                          <NavLink
                            to="/g20QuizCompetition"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            G20 Quiz Competition at DPSRU
                          </NavLink>
                        </li>
                        <li>
                          {" "}
                          <NavLink
                            to="/g20"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            G20 Cycle Rally at 'Qutub Minar'
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to="/nationalScinceDay"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        National Science Day
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/unnatBharatAbhiyan"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Unnat Bharat Abhiyan, DPSRU
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/Swachhta-Hi-seva"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Swachhta Hi seva – Ek Tareek- Ek Ghante at DPSRU
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/world-heart-day-29-sept"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        World Heart Day (29th September)
                      </NavLink>
                    </li>

                    <li className="bottom-menu-first-li">
                      <NavLink
                        className="bottom-menu-navLink-first"
                        to="/chintan-shivir"
                      >
                        CHINTAN SHIVIR
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {/* Events  */}
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/campusAmenities"
                  >
                    Campus Amenities
                  </NavLink>
                </li>

                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/grievanceForm"
                  >
                    Grievance Form
                  </NavLink>
                </li>
                {/* <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/dpsruLibrary"
                  >
                    Library
                  </NavLink>
                </li> */}
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first" to="">
                    Central resources
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink
                        to="/dpsruLibrary"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Library
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/dpsruLibrary"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        CIL
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/dpsruLibrary"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Health center
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/computerCenter"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Computer center
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink
                    to="/VisionforViksitBharat@2047/aboutViksitBharat"
                    className="sub-navlink-first bottom-menu-navLink-first"
                    // style={{ color: "blue", fontWeight: "bold" }}
                  >
                    Viksit Bharat @2047
                  </NavLink>
                </li>
                <li>
                  <a
                    href={transeferOrder}
                    className="sub-navlink-first bottom-menu-navLink-first"
                    // style={{ color: "blue", fontWeight: "bold" }}
                  >
                    Transfer Order
                  </a>
                </li>

                {/* <li className="bottom-menu-first-li search-bar-li">
                  <div className="search-bar-box">
                    <input
                      type="search"
                      name="searchbar"
                      id="search-bar-nav"
                      placeholder="Search..."
                    />
                    <button className="search-bar-button">
                      <FaSearch />
                    </button>
                  </div>
                </li> */}
              </ul>
            </nav>
          </div>

          {/* Navigation  */}

          {/* <div className="last-img-section">
                        <a href="">
                            <img className='last-img' src={img6} alt="lastimg 1" />
                            <img className='last-img' src={img7} alt="lastimg 1" />
                            <img className='last-img' src={img8} alt="lastimg 1" />
                            <img className='last-img' src={img9} alt="lastimg 1" />
                        </a>
                    </div> */}
        </div>
        {/* upper header */}

        {/* <div className="upper-header">

                  <SideNav/>
                </div> */}

        {/* Responsive navigation for mobile */}

        {/* responsive navigation-button */}
        {respNavStatus ? (
          <div className="responsive-navigation-button-container">
            {" "}
            <button
              className="responsive-navigation-button"
              onClick={() => setRespNavStatus(!respNavStatus)}
            >
              <BiX />
            </button>
          </div>
        ) : (
          <div className="responsive-navigation-button-container">
            {" "}
            <button
              className="responsive-navigation-button"
              onClick={() => setRespNavStatus(!respNavStatus)}
            >
              <VscMenu />
            </button>
          </div>
        )}

        {respNavStatus ? (
          <div className="bottom-nav responsive-bottom-navigation">
            <nav className="navbar">
              <ul className="bottom-menu-ul">
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first bottom-menu-navLink-first-home-icon"
                    to="/"
                  >
                    <FaHome />
                  </NavLink>
                </li>
                <li className="bottom-menu-navLink-first">
                  <NavLink
                    to="/aboutUs"
                    className="sub-navlink-first bottom-menu-navLink-first"
                  >
                    {" "}
                    About us
                  </NavLink>
                  {/* <ul style={{width:"300%"}}>
                    <li>
                      <NavLink
                        to="/VisionforViksitBharat@2047"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Vision for Viksit Bharat @2047
                      </NavLink>
                      </li>
                      </ul> */}
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first" to="/rd">
                    R&D
                  </NavLink>
                </li>

                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/academics"
                  >
                    Academics
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Schools
                      </NavLink>
                      <ul>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/dipsar"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Delhi Institute of Pharmaceutical Sciences and
                            Reserch
                          </NavLink>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/soPharmaceticalSciences"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            School of Pharmaceutical Sciences
                          </NavLink>
                          <ul style={{ left: "100%", width: "80px" }}>
                            <li className="inner-li-of-school-inside-of-academics">
                              {" "}
                              <NavLink
                                to="/ayurveda"
                                className="sub-navlink-first bottom-menu-navLink-first"
                              >
                                Ayurveda
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/sahsm"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            School of Allied Health Sciences and Management
                          </NavLink>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/school-of-physiotherapy"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            School of Physiotherapy
                          </NavLink>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/doctoralPrograms"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Doctoral Programmes
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to="/"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Centres
                      </NavLink>
                      <ul>
                        <li className="inner-li-of-school-inside-of-academics-centers">
                          {" "}
                          <NavLink
                            to="/wcsc"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            World Class Skill Center
                          </NavLink>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics-centers">
                          {" "}
                        </li>
                        <li className="inner-li-of-school-inside-of-academics-centers">
                          {" "}
                          <NavLink
                            to="/assrm"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            ASSRM
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to="/centresOfExcellence"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Centres of Excellence
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/certificateCourses"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Certificate Courses
                      </NavLink>
                    </li>
                    <li>
                      <a
                        href={academicSkillHub}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Skill Hub PMKVY 4.0
                      </a>
                    </li>
                    <li>
                      <a
                        href={academicCalender2425}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Academic calender-2024-25
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/administration"
                  >
                    Administration
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink
                        to="/genralCounsil"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Governing Board
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/staff"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Administrative Staff
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/organogram"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Organogram
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/committees"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Committees
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/admissions"
                  >
                    Admissions
                  </NavLink>
                  <ul style={{ width: "400px" }}>
                  <li>
                      <a
                        href={BpharmLateralEntryAdmissioninDpsugencat}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                       B.Pharm. (Lateral Entry) Admission in DPSRU, General Category
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={secondcounofBpharmlateralentry}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        SECOND COUNSELING of B.PHARM (LATERAL ENTRY)
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={nonDelhiadmissionspeicaldrive}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Non Delhi Admissions special drive
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={specialAdmissionforvariousugouses}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Special Admission Drive for various UG courses
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={vacanceyavailablefor2ndroundofcounsellformpharm}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Vacancy available for 2nd round of counselling for
                        M.Pharm
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={schedule2ndroundCOunsellingforMpharm}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Schedule 2nd Round of Counselling for M.Pharm
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={noticeforMpharmexedra24}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Cancellation notification of the admissions in respect
                        of executive M. Pharm admissions scheduled on 17/9/2024
                        <img src={newblink} alt="new" />
                      </a>
                    </li>

                    <li>
                      <a
                        href={SchedulecommonmeritListforDra2425}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Schedule Common Merit List for Executive M. Pharm (DRA)
                        2024 25
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={commonMeritlistforexecutivempharmDra2425}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Common Merit List for Executive M Pharm (DRA) 2024 25
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={AdmissionScheduleof2ndroundcounsellingforBSCss}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        ADMISSION SCHEDULE Of 2nd Round Counselling For B.SC
                        (SPORTS SCIENCE)
                        <img src={newblink} alt="new" />
                      </a>
                    </li>

                    <li>
                      <a
                        href={schedulefor2roundformbapmitggg}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Schedule for 2nd round for MBA (PM, ITM & HHM)
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={scheduleforpgAdmissionforMBAmph}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        schedule for PG Admission for MBA (MPH)
                        <img src={newblink} alt="new" />
                      </a>
                    </li>

                    <li>
                      <a
                        href={MpharmAdmissionschedule202425}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        M. Pharm Admission Schedule 2024-25
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={nd2roundcousellingormptprogrammesallbrances}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        2nd Round counselling for MPT Programmes (All Branches)
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={AdmissionScheduleforMPT}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Admission schedule for MPT
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={AdmissionScheduleforMba}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Admission Schdule for MBA
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={slidingchagingofcoursebranchinUGProgramme}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Sliding Changing of Course Branch in UG Programme
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={ugadmissionschedulefo2ndroundofcouns2425}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        UG admission schedule for 2nd round of counselling
                        2024-25
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={vaccanciesinugcoursesfrthe2ndroundcouns2425}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        vaccancies in UG courses for the 2nd round of
                        counselling 2024-25
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={redwhedulingofbpharmleadmission9aug}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Rescheduling of B.Pharm LE. Admissions{" "}
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={admissionscheduleofbpharmlaterentry}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Admission Schedule for B.Pharm. (Lateral Entry){" "}
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={newRevisedAdmissionSchedule}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        New revised admission Schedule 2024-25{" "}
                        <img src={newblink} alt="new" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={ExtensionofRegistrationdateforugcourses}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Extension of registration date for UG Courses{" "}
                        <img src={newblink} alt="new" />
                      </a>
                    </li>

                    <li>
                      <NavLink
                        to="/"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        PG Courses Merit List
                        <img src={newblink} alt="new" />
                      </NavLink>

                      <ul style={{ left: "400px" }}>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonMeritlistforMPHnondelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common merit List for MPH NON Delhi
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistmphdelhimphdehodpsru2425}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common Merit List for MPH Delhi DPSRU 2024-25
                          </a>
                        </li>

                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistforMphDelhinonDOSRU2425}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common Merit List for MPH Delhi NON DPSRU 2024-25
                          </a>
                        </li>

                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={pgcoursemeritlistno01mpharmDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Provisional Merit List Of All M.Pharm courses - [PG
                            Course] - DPSRU - Delhi
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={pgcoursesmoharnondelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Provisional Merit List Of M.Pharm courses - [PG
                            Course] - Non Delhi
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={pgcoursemeritlistno01mpharmac}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Provisional Merit List Of M.Pharm courses - [PG
                            Course] - Non-DPSRU - Delhi
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistformbahhmcoqp22delhiDpsru}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common merit List for MBA (HHM) (COQP 22) Delhi
                            DPSRU
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistformbaHHMcoqp22nondelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common merit List for MBA (HHM) (COQP 22) NON Delhi
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistmbaHHMcoqpcoqp22delhinonDPsru}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common merit List for MBA ((HHM) COQP 22) Delhi NON
                            DPSRU
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistfrmbapmanditmcoqp12delhiDPSRU}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common merit List for MBA (PM & ITM) (COQP 12) Delhi
                            DPSRU
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={
                              commonmeritlistformbapmanditmcoqp12DelhinonDPSRU
                            }
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common merit List for MBA (PM & ITM) (COQP 12) Delhi
                            NON DPSRU
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistformbapmanditmcoqp12NONDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common merit List for MBA (PM & ITM) (COQP 12) NON
                            Delhi
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistmptnonDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common Merit list MPT Non Delhi
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistMPTDelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common Merit list MPT Delhi
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <NavLink
                        to="/"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Merit List for B.Pharm. (Lateral Entry){" "}
                        <img src={newblink} alt="new" />
                      </NavLink>

                      <ul style={{ left: "400px" }}>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonMeritlistBpharmlenondelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common Merit list B.Pharm LE Non delhi
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={ewsDelhiMeritlistbpharmle}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            EWS Delhi merit list B.Pharm L.E.
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={commonmeritlistBpharmLedelhi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common Merit list B.Pharm LE Delhi
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={meritlistBpharmleDefence}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Common Merit list B.Pharm LE Defence
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={OBCMeritlistBphamrmledehi}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            OBC Delhi merit list B.Pharm L.E.
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={SCDelhiMeritlistBpharmle}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            SC Delhi merit list B.Pharm L.E.
                          </a>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <a
                            href={StDelhiMeritlistBpharmle}
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            ST Delhi merit list B.Pharm L.E.
                          </a>
                        </li>
                      </ul>
                      <li>
                        <NavLink
                          to="/"
                          className="sub-navlink-first bottom-menu-navLink-first"
                        >
                          Merit List <img src={newblink} alt="new" />
                        </NavLink>

                        <ul style={{ left: "400px" }}>
                          <li className="inner-li-of-school-inside-of-academics">
                            {" "}
                            <a
                              href={commonMeritListforDelhi}
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Common Merit List for Delhi
                            </a>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            {" "}
                            <a
                              href={commonMeritListfoNonDelhi}
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Common merit list Non-Delhi
                            </a>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            {" "}
                            <a
                              href={EWSMeritlistforDelhi}
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              EWS Merit List for Delhi
                            </a>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            {" "}
                            <a
                              href={EWSMeritlistforNonDelhi}
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              EWS Merit List for NON Delhi
                            </a>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            {" "}
                            <a
                              href={OBCMeritlistforDelhi}
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              OBC Merit List for Delhi
                            </a>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            {" "}
                            <a
                              href={scMeritlistforDelhi}
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              SC Merit List for Delhi
                            </a>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            {" "}
                            <a
                              href={scMeritlistforNonDelhi}
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              SC  Merit List for NON Delhi
                            </a>
                          </li>
                          <li className="inner-li-of-school-inside-of-academics">
                            {" "}
                            <a
                              href={STMeritlistforDelhi}
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              ST Merit List for Delhi
                            </a>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            {" "}
                            <a
                              href={STMeritlistforNonDelhi}
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              ST Merit List for NON Delhi
                            </a>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            {" "}
                            <a
                              href={MeriListofBscsportsscienceDelhi}
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Merit List of B.Sc Sports Science DELHI
                            </a>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            {" "}
                            <a
                              href={MeriListofBscsportsscienceNonDelhi}
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Merit List of B.Sc Sports Science NON DELHI
                            </a>
                          </li>
                          <li className="inner-li-of-school-inside-of-academics">
                            {" "}
                            <a
                              href={pwdmeritlist2024}
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              PWD merit list 2024
                            </a>
                          </li>
                          <li className="inner-li-of-school-inside-of-academics">
                            {" "}
                            <a
                              href={defenceMaritlistData}
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Defence merit list 2024
                            </a>
                          </li>
                          <li className="inner-li-of-school-inside-of-academics">
                            {" "}
                            <a
                              href={kasmirimirgrantmeritlistdata}
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              kashmiri migrants Merit list 2024
                            </a>
                          </li>
                        </ul>
                      </li>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first" to="/students">
                    Students
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/scholarShips"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Scholarships
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/hostel"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Hostal
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/examination"
                  >
                    Examinations
                  </NavLink>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first">IQAC</NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/iaqcIntroduction"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Introduction
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/iqacMoM"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        IQAC Committee (Composition and MoM)
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/QualityInitiativeIAQC"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Quality Initiatives
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/NationalRankingIAQC"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        National Rankings
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/International-Accolades"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        International Accolades
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/iaqcFeedback"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Feedback
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/iqacEvent"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        IQAC Events
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/iqacPolicy"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/contactUs"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Contact Us
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first">
                    Accreditations & Approvals
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/naac"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        NAAC
                      </NavLink>
                    </li>
                    <li>
                      <a
                        href={naacAccreditationgradeseetjuly24}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        NAAC Accreditation Grade Sheet July 2024
                      </a>
                    </li>
                    <li>
                      <NavLink
                        to="/nirf"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        NIRF
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/atalRanking"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Atal Rankings
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/approvals"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Approvals
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first" to="/">
                    RTI
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/rtiAct"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        RTI Act
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="sub-navlink-first bottom-menu-navLink-first">
                        Section (4)(1)(b) of RTI Act
                      </NavLink>
                      <ul>
                        <li>
                          <NavLink
                            to="/organizationAndFunction"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Organization and Function
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/budgetAndProgramme"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Budget and Programme
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/ppiRti"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Publicity and Public Interface
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/eGovernance"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            E-Governance
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/informationAsMayBePrescribed"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Information as may be prescribed
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/informationDisclosedOnOwnInitiative"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Information Disclosed on own Initiative
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/annualReportOfRti"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Annual Report of RTI
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to="/cpioAndFaa"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        CPIO and FAA
                      </NavLink>
                    </li>
                  </ul>
                </li>

                {/* Events  */}
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/events
                    "
                  >
                    Events
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink
                        to="/conference"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Conference
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/ucps"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Seminar
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/workshop"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Workshop
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/g20"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        G20
                      </NavLink>
                      <ul>
                        <li>
                          {" "}
                          <NavLink
                            to="/g20PanelDiscussionReport"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Panel Discussion
                          </NavLink>
                        </li>
                        <li>
                          {" "}
                          <NavLink
                            to="/indoBrazilFusionFest"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Indo Brazil Fusion Fest
                          </NavLink>
                        </li>
                        <li>
                          {" "}
                          <NavLink
                            to="/pushpPradarshnam"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Pushp Pradarshnam 2023
                          </NavLink>
                        </li>
                        <li>
                          {" "}
                          <NavLink
                            to="/G20MahaFoodFestival"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            G20 maha food festival
                          </NavLink>
                        </li>
                        <li>
                          {" "}
                          <NavLink
                            to="/g20QuizCompetition"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            G20 Quiz Competition at DPSRU
                          </NavLink>
                        </li>
                        <li>
                          {" "}
                          <NavLink
                            to="/g20"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            G20 Cycle Rally at 'Qutub Minar'
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to="/nationalScinceDay"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        National Science Day
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/unnatBharatAbhiyan"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Unnat Bharat Abhiyan, DPSRU
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/Swachhta-Hi-seva"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Swachhta Hi seva – Ek Tareek- Ek Ghante at DPSRU
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/world-heart-day-29-sept"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        World Heart Day (29th September)
                      </NavLink>
                    </li>

                    <li className="bottom-menu-first-li">
                      <NavLink
                        className="bottom-menu-navLink-first"
                        to="/chintan-shivir"
                      >
                        CHINTAN SHIVIR
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {/* Events  */}
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/campusAmenities"
                  >
                    Campus Amenities
                  </NavLink>
                </li>

                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/grievanceForm"
                  >
                    Grievance Form
                  </NavLink>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/dpsruLibrary"
                  >
                    Library
                  </NavLink>
                </li>

                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first" to="">
                    Central resources
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink
                        to="/dpsruLibrary"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Library
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/dpsruLibrary"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        CIL
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/dpsruLibrary"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Health center
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/computerCenter"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Computer center
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink
                    to="/VisionforViksitBharat@2047/aboutViksitBharat"
                    className="sub-navlink-first bottom-menu-navLink-first"
                    style={{ color: "blue", fontWeight: "bold" }}
                  >
                    Viksit Bharat @2047
                  </NavLink>
                </li>

                <li>
                  <a
                    href={transeferOrder}
                    className="sub-navlink-first bottom-menu-navLink-first"
                    // style={{ color: "blue", fontWeight: "bold" }}
                  >
                    Transfer Order
                  </a>
                </li>

                {/* search bar */}
                {/* <li className="bottom-menu-first-li search-bar-li">
                  <div className="search-bar-box ">
                    <input
                      type="search"
                      name="searchbar"
                      id="search-bar-nav"
                      placeholder="Search..."
                    />
                    <button className="search-bar-button">
                      <FaSearch />
                    </button>
                  </div>
                </li> */}
                {/* search bar */}
              </ul>
            </nav>
          </div>
        ) : null}

        {/* Responsive navigation */}
      </header>
    </div>
  );
}

export default Nav;
